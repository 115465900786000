'use client';

import React from 'react';
import { usePostHog } from 'posthog-js/react';
import { usePathname, useSearchParams } from 'next/navigation';

function Component(): null {
  const posthog = usePostHog();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  React.useEffect(() => {
    if (pathname && posthog) {
      let url = window.origin + pathname;

      if (searchParams.toString()) {
        url = url + `?${searchParams.toString()}`;
      }

      posthog?.capture('$pageview', {
        $current_url: url,
      });
    }
  }, [pathname, searchParams, posthog]);

  return null;
}

export default function PostHogPageView() {
  return (
    <React.Suspense>
      <Component />
    </React.Suspense>
  );
}
