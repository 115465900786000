'use client';

import React from 'react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

if (typeof window !== 'undefined') {
  const POSTHOG_HOST = 'https://us.i.posthog.com';
  const POSTHOG_KEY = 'phc_spIOXf2Rx5yLdmzZR7tM2Gzoo1FVmHJqk3xk0eAgR92';

  if (POSTHOG_KEY && POSTHOG_HOST) {
    posthog.init(POSTHOG_KEY, {
      api_host: POSTHOG_HOST,
      capture_pageview: true,
      person_profiles: 'always',
    });
  }
}

export function PHProvider({ children }: { children: React.ReactNode }) {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
