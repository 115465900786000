import(/* webpackMode: "eager", webpackExports: ["ConfigProvider","App"] */ "__barrel_optimize__?names=App,ConfigProvider!=!/app/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/animate.css/animate.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/global.css");
;
import(/* webpackMode: "eager" */ "/app/src/app/PostHogPageView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PHProvider"] */ "/app/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReduxProvider"] */ "/app/src/lib/providers/reduxProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Box"] */ "/app/src/shared/ui/box.tsx");
